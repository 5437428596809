<template>
  <div>
    <NuxtLoadingIndicator />
    <OrganismsGlobalBanner />
    <SiteHeader :menu="primaryMenu" />

    <slot />

    <SiteFooter
      :collections="footerCollectionsMenu"
      :neighborhoods="footerNeighborhoodsMenu"
    />
  </div>
</template>

<script setup>
import { useQuery } from "@vue/apollo-composable";
import { GET_MENUS } from "~/graphql/GetMenus";

const { result, loading } = useQuery(GET_MENUS);

const primaryMenu = computed(() => {
  const flatMenu = result.value?.primaryMenu?.menuItems?.nodes ?? [];
  if (flatMenu.length === 0) return [];
  return flatMenu.length === 0 ? [] : flatListToHierarchical(flatMenu);
});

const footerCollectionsMenu = computed(() => {
  return result.value?.footerCollectionsMenu?.nodes ?? [];
});

const footerNeighborhoodsMenu = computed(() => {
  return result.value?.footerNeighborhoodsMenu?.nodes ?? [];
});
</script>
